.App {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: auto;
  width: 500px;
  background-color: rgb(150, 190, 247);
  border-radius: 15px;
}

label {
  display: block;
  margin-bottom: 20px;
}

input {
  margin-left: 10px;
}
body {
  background-color: lightgray;
}
* {
  padding-top: 10px;
}
.bot {
  padding: 20px;
}
input {
  margin-left: 10px;
  box-shadow: 0px 5px 5px 0px black;
}
